require('../vendor/jquery.datetimepicker');
require('../vendor/selectize');

var repopulateSelectizer = require('./repopulate_selectizer').repopulateSelectizer;
var pubsub = require('pubsub/pubsub.min');

function getCompany() {
  if ($('#id_company').length > 0) {
    return $('#id_company');
  }

  return $('#id_company');
}

/**
 * This is seriously stupid as fuck. The tests page has a different
 * id for the job type and job number select fields that the
 * appointments page does (even though they are the same data object),
 * so we need to figure out which one exists, and then use that for our
 * selectors in the actual module.
 *
 * p.s.: this wasn't our choice and I'm not blaming Lift devs, that was
 * something that was built into the system by another company -_-
 *
 * p.p.s.: i would like to thank our Nepalese counterparts for making us
 * solve problems tougher than world hunger and political correctness.
 */
function getJobType() {
  var $multiJobTypes = $('#id_job_types');
  var $apptJobType = $('#id_job_type');
  var $testJobType = $('#id_company_job_type');
  var $userprofileJobType = $('#id_company_job_types');

  if ($apptJobType.length > 0) {
    return $apptJobType;
  } else if ($testJobType.length > 0) {
    return $testJobType;
  } else if ($userprofileJobType.length > 0) {
    return $userprofileJobType;
  } else if ($multiJobTypes.length > 0) {
    return $multiJobTypes;
  }

  return [];
}

/**
 * This is basically the same as the getJobType above, but for the
 * Job Number data object instead.
 */
function getJobNumber() {
  var $apptJobNum = $('#id_job_number');
  var $multiJobNumbers = $('#id_job_numbers');
  var $testJobNum = $('#id_company_job');
  var $trackingJobNum = $('#id_job');
  var $userprofileJobNum = $('#id_company_jobs');

  if ($apptJobNum.length > 0) {
    return $apptJobNum;
  } else if ($testJobNum.length > 0) {
    return $testJobNum;
  } else if ($trackingJobNum.length > 0) {
    return $trackingJobNum;
  } else if ($userprofileJobNum.length > 0) {
    return $userprofileJobNum;
  } else if ($multiJobNumbers.length > 0) {
    return $multiJobNumbers;
  }

  return [];
}

function getBookingGroup() {
  var $bookingGroup = $('#id_booking_group');

  if ($bookingGroup.length > 0) {
    return $bookingGroup;
  }

  return [];
}

module.exports = {
  // there's a few select boxes that we utilize in various
  // areas so we define their jQuery selectors in the module's
  // root scope here so that we have access to the same
  // object throughout and don't accidentally create
  // duplicate objects/selectors
  $bookingGroup: getBookingGroup(),
  $bookingGroupCtrl: null,
  $company: getCompany(),
  $companyCtrl: null,
  $companyAppt: $('#companyAppt'),
  $jobNumber: getJobNumber(),
  $jobNumberCtrl: null,
  $recruiter: $('#id_recruiter'),
  $recruiterCtrl: null,
  $jobType: getJobType(),
  $jobTypeCtrl: null,
  $tradeCtrl: null,
  $special: $('.form-special-instructions'),
  $medservicesspecial: $('.form-med-services-special-instructions'),
  $policies: $('.form-company-policies'),
  companyID: '',
  currentModel: null,
  firstRun: true,
  selectizeConfig: GLOBAL.selectizeConfig,

  init: function(model) {
    var _this = this; // store current scope
    this.currentModel = model;

    if (this.$company.length > 0) {
      // We need to do this rather obtuse statement so that we can
      // have direct access to the selectize() instance and don't
      // have to re-write the [0].selectize everytime we want to
      // access one of the select menu's selectize instance
      var companyConf = {
        maxOptions: this.selectizeConfig.maxOptions,
        selectOnTab: this.selectizeConfig.selectOnTab,
        allowEmptyOption: this.selectizeConfig.allowEmptyOption,
        onChange: function(value) {
          _this.companyChanged(value);
        }
      };
      this.$companyCtrl = this.$company.selectize(companyConf)[0].selectize;

      if (this.$jobNumber.length > 0 && this.currentModel !== 'jobnumber-admin') {
        this.$jobNumberCtrl = this.$jobNumber.selectize(this.selectizeConfig)[0].selectize;
      }

      if (this.$recruiter.length > 0 && this.$recruiter.attr('type') !== 'hidden' && this.$recruiter.attr('type') !== 'text') {
        this.$recruiterCtrl = this.$recruiter.selectize(this.selectizeConfig)[0].selectize;
      }

      if (this.$bookingGroup.length > 0 && this.$bookingGroup.attr('type') !== 'hidden' && this.$bookingGroup.attr('type') !== 'text') {
        this.$bookingGroupCtrl = this.$bookingGroup.selectize(this.selectizeConfig)[0].selectize;
      }

      if (this.$companyCtrl.getValue() === '') {
        // Users should only be able to select these after
        // they have selected a company
        if (this.$jobTypeCtrl !== null) { this.$jobTypeCtrl.disable(); }
        if (this.$jobNumberCtrl !== null) { this.$jobNumberCtrl.disable(); }
        if (this.$recruiterCtrl !== null) { this.$recruiterCtrl.disable(); }
        if (this.$bookingGroupCtrl !== null) { this.$bookingGroupCtrl.disable(); }
      }
    }

    // This used to sit inside the above if block, however Booking Recruiter's
    // Book Appointment page doesn't use a company field when booking.
    if (this.$jobType.length > 0) {
      var jobTypeConf = {
        maxOptions: this.selectizeConfig.maxOptions,
        selectOnTab: this.selectizeConfig.selectOnTab,
        allowEmptyOption: this.selectizeConfig.allowEmptyOption,
        onChange: function(value) {
          if (['reports', 'collection-site', 'bookinggroup-admin'].indexOf(_this.currentModel) === -1) {
            _this.jobTypeChanged(value);
          }
        }
      };

      this.$jobTypeCtrl = this.$jobType.selectize(jobTypeConf)[0].selectize;

      if (this.$companyCtrl && this.$companyCtrl.getValue() === '') {
        this.$jobTypeCtrl.disable();
      }
    }

    /* Provide selectizer behaviour for the union/trade boxes. */
    this.unionSelectizeConfig = {
       maxOptions: 10000,
       selectOnTab: true,
       allowEmptyOption: false,
       onChange: function() {
         _this.unionChanged();
       }
    };
    if ($('#id_union').length > 0) {
      $('#id_union').selectize(this.unionSelectizeConfig);
    }
    if ($('#id_trade').length > 0) {
      this.$tradeCtrl = repopulateSelectizer($('#id_trade'), null, this.selectizeConfig, null);
    }
  },

  /**
   * CompanyChanged fires everytime the "company" select
   * box changes
   */
  companyChanged: function(value) {
    // Store the new ID value
    this.companyID = value;

    // As long as our ID isn't equal to an empty string
    // (the empty choice at the top of the select menu)
    // then we fire our Ajax calls
    if (this.companyID !== '') {
      if (['jobnumber-admin', 'jobtype-admin', 'recruiter-admin'].indexOf(this.currentModel) === -1) {
        this.updateJobNumber();
        this.updateJobType();
      }

      if (['collection-site'].indexOf(this.currentModel) === -1) {
        this.updateSpecialInstructions();
      }

      if (this.currentModel === 'bookinggroup-admin') {
        this.updateUsers(['#id_notification_users']);
      } else if (this.currentModel === 'jobtype-admin') {
        this.updateUsers(['#id_job_type_users']);
        this.updateJobNumbers(['#id_job_numbers']);
      }

      if (['collection-site', 'jobnumber-admin', 'jobtype-admin', 'recruiter-admin', 'reports', 'bookinggroup-admin'].indexOf(this.currentModel) === -1) {
        this.updateDefaultTests();
      }
    } else {
      // Make sure our controllers are initiated, otherwise nothing
      // has happened, the user just re-selected "nothing" as the
      // company ID so disable the appropriate fields
      if (this.$jobNumberCtrl !== null) { this.$jobNumberCtrl.disable(); }
      if (this.$recruiterCtrl !== null) { this.$recruiterCtrl.disable(); }
      if (this.$jobTypeCtrl !== null) { this.$jobTypeCtrl.disable(); }

      this.$special.empty();
      this.$policies.empty();
    }
  },

  /**
   * jobTypeChanged fires whenever the Job Type select field
   * changes. If it changes, it makes an Ajax request for the
   * default tests and then selects/shows those tests in the
   * tests display.
   */
  jobTypeChanged: function(value) {
    // Get the new ID value
    var jobTypeID = value;

    // Retrieve tests information
    if (jobTypeID !== '') {
      $.ajax({
        url: '/ajax/company-jobtype-tests/',
        data: {
          'id': jobTypeID
        }
      })
      .done(function(data) {
        // notify other modules that jobType has changed
        pubsub.publish('defaultTests', {
          test_ids: data.test_ids
        });
      })
      .fail(function(data) {
        console.log(data);
      });
    } else {
      // This is happening because the user might've selected a
      // different company, or for some reason they set "Job Type"
      // back to null, either way, we need to let our pubsub know
      // to empty all of the previously selected tests
      pubsub.publish('defaultTests', {
        test_ids: []
      });
    }
  },

  getUnionAndTradeWidgets: function(view) {
    /* Build union and trade widgets in the event that the
       Audiometric Screen - SUREAUDIO test is selected.
       The view argument specifies whether this is being done for appointments
       or for the screening page. */
    var _this = this;
    var appointmentId = null;
    var appointmentIdBox = $('#id_surebook_id');
    if (appointmentIdBox.length > 0) {
      appointmentId = appointmentIdBox.val();
    }
    $.ajax({
      url: '/ajax/union_and_trade_widgets/',
      data: {'view': view, 'appointment_id': appointmentId}
    })
    .done(function(data) {
      if (view === 'appointment_view') {
        /* Insert the html underneath the job type drop down */
        $(data.widgets).insertAfter($('#id_job_type').closest('.form-group'));
        /* Selectize the union box (don't need to store the reference) */
        $('#id_union').selectize(_this.unionSelectizeConfig);
        /* Creates a selectizer instance and returns a reference to it */
        _this.$tradeCtrl = repopulateSelectizer($('#id_trade'), null, _this.selectizeConfig, null);
      } else {
        // Different layout
        /* Insert the html underneath the id info */
        $(data.widgets).insertAfter($('#id_id_number').closest('.row'));
        /* Selectize the union box (don't need to store the reference) */
        $('#id_union').selectize(_this.unionSelectizeConfig);
        /* Creates a selectizer instance and returns a reference to it */
        _this.$tradeCtrl = repopulateSelectizer($('#id_trade'), null, _this.selectizeConfig, null);
      }
    })
    .fail(function(data) {
      console.log('Ajax call failed - getting union and trade widgets');
      console.log(data);
    });
  },

  removeUnionAndTradeWidgets: function() {
    /* Remove union and trade widgets when Audiometric Screen - SUREAUDIO
       is unselected. */
    if ($('.unions_and_trades').length > 0) {
      $('.unions_and_trades').remove();
    }
    /* In the appointment admin change view (i.e. for booking agents) the
       union and trade boxes start out in their own .rows. In all other
       appointment views, they are in the .unions_and_trades. */
    if ($('#id_union').length > 0) {
      $('#id_union').closest('.row').remove();
    }
    if ($('#id_trade').length > 0) {
      $('#id_trade').closest('.row').remove();
    }
  },

  unionChanged: function() {
    /* When the union dropdown changes, we need to reload the values in the trade dropdown. */
    var _this = this;
    var union = $('#id_union').val();
    $.ajax({
      url: '/ajax/trades/',
      data: {
        'union': union
      }
    })
    .done(function(data) {
      repopulateSelectizer($('#id_trade'), _this.$tradeCtrl, _this.selectizeConfig, data);
    })
    .fail(function(data) {
      console.log('Ajax call failed - Attempted to load trades dropdown data');
      console.log(data);
    });
  },

  /**
   * Updates our Job Number select field
   */
  updateJobNumber: function() {
    var _this = this;
    // On certain views, our job number select <option> elements need to use
    // the 'string' version, and on certain views they need the 'id' version,
    // so we need to get the information from the appropriate URL, ie:
    // <option value="KDeOx">KDeOx</option>
    // vs:
    // <option value="3454">KDeOx</option>
    //
    // Moreover, on the User page, the UserProfile's Company Jobs dropdown needs to
    // show all the job numbers, including those which have expired.
    var jobNumberURL = '';
    var selectedValues = [];

    if (['collection-site', 'reports', 'tracking', 'bookinggroup-admin'].indexOf(this.currentModel) >= 0) {
      // This Ajax URL returns CompanyJob pks as values
      jobNumberURL = '/ajax/company-job-numbers-test-result/';
    } else {
      // This Ajax URL returns CompanyJob name strings as values
      jobNumberURL = '/ajax/company-job-numbers/';
    }

    this.$jobNumber.find(':selected').each(function(idx, el) {
      selectedValues.push(parseInt(el.value, 10));
    });

    // Cleanup existing elements
    if (this.$jobNumber.length > 0) {
      this.$jobNumber.empty();

      // Retrieve company job numbers
      $.ajax({
        url: jobNumberURL,
        data: {
          'id': this.companyID
        }
      })
      .done(function(data) {
        repopulateSelectizer(_this.$jobNumber, _this.$jobNumberCtrl, _this.selectizeConfig, data);
      })
      .fail(function(data) {
        console.log('Ajax call failed — Job Numbers');
        console.log(data);
      });
    }
  },


  /**
   * Updates our Job Type select field
   */
  updateJobType: function() {
    var _this = this;
    // On certain pages, our job number select <option> elements need to use
    // the 'string' version, and on certain views they need the 'id' version,
    // so we need to get the information from the appropriate URL, ie:
    // <option value="KDeOx">KDeOx</option>
    // vs:
    // <option value="3454">KDeOx</option>
    /* Clear union and trade fields if they exist - they should only appear when a job type that
       requires them is selected. This rule only applies to appointment views. */
    $('.unions_and_trades').remove();
    var jobTypeURL = (this.currentModel === 'collection-site') ? '/ajax/company-job-type-test-result/' : '/ajax/company-job-type/';
    var removeRandomProgram = false;

    // Cleanup existing elements
    if (this.$jobType.length > 0) {
      this.$jobType.empty();

      // Retrieve company job types
      $.ajax({
        url: jobTypeURL,
        data: {
          'id': this.companyID,
          'removeRandomProgram': removeRandomProgram
        }
      })
      .done(function(data) {
        // Reload and re-enable input
        repopulateSelectizer(_this.$jobType, _this.$jobTypeCtrl, _this.selectizeConfig, data);
      })
      .fail(function(data) {
        console.log('Ajax call failed — Job Types');
        console.log(data);
      });
    }
  },

  /**
   * Updates our Booking Group select field
   *
   * Unused right now, I realized I didn't need this. Keeping for future reference.
   */
  updateBookingGroup: function() {
    var _this = this;

    // Cleanup existing elements
    if (this.$bookingGroup.length > 0) {
      this.$bookingGroup.empty();

      // Retrieve company job types
      $.ajax({
        url: '/ajax/company-booking-group/',
        data: {
          'id': this.companyID
        }
      })
      .done(function(data) {
        // Reload and re-enable input
        repopulateSelectizer(_this.$bookingGroup, _this.$bookingGroupCtrl, _this.selectizeConfig, data);
      })
      .fail(function(data) {
        console.error('Ajax call failed — Booking Group');
        console.error(data);
      });
    }
  },


  /**
   * Updates the Special Instructions panel on some admin pages.
   */
  updateSpecialInstructions: function() {
    var _this = this;

    if (this.$special.length === 0) {
      return;  // Do nothing if our selector couldn't find the block
    }

    // Cleanup existing elements
    this.$special.empty();

    var specialInstructionsURL = '/ajax/company-instruction/';
    var specialInstructionsData = {};

    if (['jobnumber-admin', 'jobtype-admin', 'recruiter-admin', 'bookinggroup-admin'].indexOf(this.currentModel) >= 0) {
      specialInstructionsURL = '/ajax/company-admin-special-instructions/' + this.companyID + '/';
    } else {
      specialInstructionsData = {'id': this.companyID};
    }

    // Retrieve special instructions
    $.ajax({
      url: specialInstructionsURL,
      data: specialInstructionsData
    })
    .done(function(data) {
      if (data.instruction) {
        _this.$special.append('<p class="form-special-instructions">' + data.instruction + '</p>');
      }
    })
    .fail(function(data) {
      console.log('Ajax call failed — Special Instructions');
      console.log(data);
    });
  },

  /**
   * Updates the default tests attached to a company
   */
   updateDefaultTests: function() {
    // Retrieve tests information
    if (this.companyID !== '') {
      $.ajax({
        url: '/ajax/company-default-tests/',
        data: {
          'id': this.companyID
        }
      })
      .done(function(data) {
        console.log('update default tests', data);
        // notify other modules that jobType has changed
        pubsub.publish('defaultTests', {
          test_ids: data.test_ids
        });
      })
      .fail(function(data) {
        console.log('Ajax call failed — Getting Company Default Tests');
        console.log(data);
      });
    } else {
      // This is happening because the user might've selected a
      // different company, or for some reason they set "Job Type"
      // back to null, either way, we need to let our pubsub know
      // to empty all of the previously selected tests
      pubsub.publish('defaultTests', {
        test_ids: []
      });
    }
  },

  updateUsers: function(idTags) {
    var _this = this;
    this.selectizeCtrlList = [];
    var i;
    for (i = 0; i < idTags.length; i++) {
      this.selectizeCtrlList.push($(idTags[i]).selectize(GLOBAL.selectizeConfig)[0].selectize);
    }

    for (i = 0; i < idTags.length; i++) {
      /* Update the fields. */
      this.selectizeCtrlList[i].clearOptions();
      this.selectizeCtrlList[i].disable();
    }

    if (this.companyID === null || this.companyID === '') {
      return;
    }
    $.ajax({
      url: '/ajax/company-data-active-users/',
      data: {'companyID': this.companyID}
    }).done(function(data) {
      for (var j = 0; j < idTags.length; j++) {
        repopulateSelectizer($(idTags[j]), _this.selectizeCtrlList[j], null, data);
      }
    });
  },

  updateJobNumbers: function(idTags) {
    var _this = this;
    this.selectizeCtrlList = [];
    var i;
    for (i = 0; i < idTags.length; i++) {
      this.selectizeCtrlList.push($(idTags[i]).selectize(GLOBAL.selectizeConfig)[0].selectize);
    }

    for (i = 0; i < idTags.length; i++) {
      /* Update the fields. */
      this.selectizeCtrlList[i].clearOptions();
      this.selectizeCtrlList[i].disable();
    }

    if (this.companyID === null || this.companyID === '') {
      return;
    }
    $.ajax({
      url: '/ajax/company-data-job-numbers/',
      data: {'companyID': this.companyID}
    }).done(function(data) {
      for (var j = 0; j < idTags.length; j++) {
        repopulateSelectizer($(idTags[j]), _this.selectizeCtrlList[j], null, data);
      }
    });
  }
};
