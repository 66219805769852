// Vendor/Bower Libraries
// This also ensures that these libraries are bundled specifically
// into this main App.js file instead of being included multiple
// times into the children chunks
require('./vendor/jquery.datetimepicker');
require('./vendor/selectize');
require('pubsub/pubsub.min.js');
require('./modules/company_ajax');
require('multiselect/js/jquery.multi-select.js');
require('./vendor/jquery.quicksearch');
require('./modules/related-widget-wrapper');
require('./modules/group_tests');
require('./modules/preventSubmission');
require('bootstrap/js/dist/modal.js');

// @props: http://viget.com/inspire/extending-paul-irishs-comprehensive-dom-ready-execution
(function(window, $) {
  var LIFT;
  var UTIL;

  LIFT = {
    /**
     * Discovered some serious lag/DOM issues with the more generic way
     * I was doing things previously so I have reflected the entire Django
     * apps with their own mirror Javascript to make everything more
     * explicit and easy to track down/fix in the future. This is also
     * reflected in a change with `change_form.html` for each of the
     * Apps inside of the `templates/admin/` folder.
     */
    admin: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./admin').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    adminactions: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./adminactions').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    appointment: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./appointments').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    affidavits: {
      init: function() {
        require.ensure([], function() {
          require('./affidavits').init();
        });
      }
    },

    aiccc_backend: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./aiccc_backend').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    aiccc: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./aiccc').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    account_setup: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./account_setup').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    account_setup_code: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./account_setup_code').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    testing_reminders: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./testing_reminders').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    blocks: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./blocks').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    bookinggroup_admin: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./bookinggroup_admin').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    calendars: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./calendars').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    new_calendars: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./new_calendars').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    // client: {
    //   init: function() {
    //     require.ensure([], function() {
    //       require('./client').init();
    //     });
    //   }
    // },

    // cloud_drive: {
    //   init: function() {
    //     require.ensure([], function() {
    //       require('./cloud_drive').init();
    //     });
    //   }
    // },

    collection_site: {
      init: function() {
        require.ensure([], function() {
          require('./collection_site').init();
        });
      }
    },

    collection_site_add: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./collection_site_add').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    companies: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./companies').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    credential: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./credential').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    custom_reports_clr: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./custom_reports/CLR_drug_and_alc').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    // dbtemplates: {
    //   init: function() {
    //     require.ensure([], function() {
    //       require('./dbtemplates').init();
    //     });
    //   }
    // },

    donottest: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./donottest').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    clearance_ohs: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./clearance_ohs').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    covid_19_appointment_questionnaire: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./covid_19_appointment_questionnaire').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    covid_19_daily_questionnaire: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./covid_19_daily_questionnaire').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    homepage: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./homepage').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    lms: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./lms').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    notifications: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./notifications').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    servicerecords_internalbookings: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./servicerecords/internalbookings').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    servicerecords_externalbookings: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./servicerecords/externalbookings').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    servicerecords_externalbookingsedit: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./servicerecords/externalbookingsedit').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    servicerecords_results: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./servicerecords/results').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    email_results: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./servicerecords/email_results').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    servicerecords_resultoption: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./servicerecords/resultoptions').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    servicerecords_reports: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./servicerecords/reports').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    // extra_utils: {
    //   init: function() {
    //     require.ensure([], function() {
    //       require('./extra_utils').init();
    //     });
    //   }
    // },

    jobnumber_admin: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./jobnumber_admin').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    jobtype_admin: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./jobtype_admin').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    notificationcompanycontactlist: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./notificationcompanycontactlist').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    notificationcollectionsitecontactlist: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./notificationcollectionsitecontactlist').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    maps: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./maps').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    participantbookingcode_admin: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./participantbookingcode_admin').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    policies: {
      init: function() {
        require.ensure([], function() {
          require('./vendor/selectize');

          var $company = $('#id_company');
          if ($company.length > 0) {
            $company.selectize({
              maxOptions: 10000,
              selectOnTab: true
            });
          }
        });
      }
    },

    participant: {
      init: function() {
        require.ensure([], function() {
          require('./participant').init();
        });
      }
    },

    qr_code: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./qr_code').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    recruiter_admin: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./recruiter_admin').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    reports: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./reports').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    // schedule: {
    //   init: function() {
    //     require.ensure([], function() {
    //       require('./schedule').init();
    //     });
    //   }
    // },

    search: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./search').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    sureconnect: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('../../surelink_project/sureconnect_sms_frontend/src/index');
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    icos_react_app: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('../../surelink_project/icos_frontend/src/index');
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    status_bars: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./servicerecords/statusBar.js').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    sureforms: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./sureforms').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    tracking: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./tracking').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    user_auth: {
      init: function() {
        require.ensure([], function() {
          require('./user_auth').init();
        });
      }
    },

    userprofile: {
      init: function() {
        require.ensure([], function() {
          require('./userprofile').init();
        });
      }
    },

    wire: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./wire').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    wire_add: {
      init: function() {
        require.ensure([], function() {
          var initID = setTimeout(function() {
            require('./wire-add').init();
            clearTimeout(initID);
          }, 0);
        });
      }
    },

    custom_user_profile: {
      init: function() {
        require.ensure([], function() {
          require('./custom_user_profile').init();
        });
      }
    },

    // common gets called for every single page/view which means
    // global elements should be contained in here
    // ie. global navigation
    common: {

      /**
       *  `init` gets called by default, but we're just going to use it
       *  to call our other functions to keep our individual modules
       *  clean and compartmentalized
       */
      init: function() {
        var initID = setTimeout(function() {
          // Custom Stuff
          require('./modules/sidebar-menu').init();
          require('./modules/iconNav-dropdown').init();
          require('./modules/single-submit').init();
          require('./modules/table-sorter').init();

          $('#overlay').delay(300).fadeOut(500);
          clearTimeout(initID);
        }, 0);

      }
    }
  };

  UTIL = {
    exec: function(model, action) {
      var ns = LIFT;

      var modelAction = (action === undefined) ? 'init' : action;

      if (model !== '' && ns[model] && typeof ns[model][modelAction] === 'function') {
        ns[model][modelAction]();
      }
    },

    init: function() {
      // the following looks for an element with a class "mainblock"
      // and then pulls the value of it's data-model attribute
      // ie.: <div class="mainblock" data-model="myModel">
      var initializer = document.getElementsByClassName('l-sitewrap');
      var model = initializer[0].getAttribute('data-model');
      var action = initializer[0].getAttribute('data-action');

      UTIL.exec('common');      // calls LIFT.common.init()
      UTIL.exec(model);         // calls LIFT.model.init()
      UTIL.exec(model, action); // calls LIFT.model.action()
    }
  };

  // DOM ready, let's DO DIS!
  $(document).ready(UTIL.init);

})(window, jQuery);
